<template>
  <TheNavbar />

  <div class="row text-center mt-5 mb-3">
    <div class="col">
      <h2 class="">Create New Bot</h2>
    </div>
  </div>

  <form @submit.prevent="">
    
    <div v-if="step == 1 && platformExchanges">

      <div class="row justify-content-center">
        <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">

          <div class="card mb-3">
            <div class="card-header">
              <div class="row flex-between-end">
                <div class="col-auto text-center">
                  <h5 class="mb-0" data-anchor="data-anchor">Select Exchange</h5>
                </div>
              </div>
            </div>
            <div class="card-body bg-body-tertiary text-start">

              <div class="row row-cols-5 row-cols-sm-5 row-cols-md-5 row-cols-lg-5 row-cols-xl-5 row-cols-xxl-5 g-2 g-xl-3 g-xxl-3 justify-content-center align-items-center">
                <div v-for="exchange in platformExchanges" :key="exchange.id" class="col">
                  <!-- <img v-if="exchange" @click="selectExchange(exchange)" :class="'exchange img-fluid rounded mx-auto d-block p-0 p-sm-1 p-md-1 p-xl-0 '+getSelectedExchangeClasses(exchange.id)" :src="'/exchanges/'+exchange.id+'.png'" :title="exchange.name" /> -->
                  
                  <img v-if="exchange.supported" @click="selectExchange(exchange)" :class="'exchange img-fluid rounded mx-auto d-block p-0 p-sm-1 p-md-1 p-xl-0 '+getSelectedExchangeClasses(exchange.id)" :src="'/exchanges/'+exchange.id+'.png'" :title="exchange.name" />
                  <img v-else-if="!exchange.supported" :class="'exchange img-fluid rounded mx-auto d-block p-0 p-sm-1 p-md-1 p-xl-0 '+getSelectedExchangeClasses(exchange.id)" :src="'/exchanges/'+exchange.id+'_comingsoon.png'" :title="exchange.name" />
                </div>
              </div>

            </div>
          </div>

        </div> <!-- col -->
      </div> <!-- row -->

      <div v-if="selectedExchange" class="row justify-content-center">
        <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">

          <div class="card mb-3">
            <div class="card-header">
              <div class="row flex-between-end">
                <div class="col-auto text-center">
                  <h5 class="mb-0" data-anchor="data-anchor">Validate {{ selectedExchange.name }} API Credentials</h5>
                </div>
              </div>
            </div>
            <div class="card-body bg-body-tertiary text-start">

              <div class="row mb-1 justify-content-center">
                <div class="col-6">
                  <div class="form-floating">
                    <input v-model="form.exchangeConfig.apiKey" class="form-control" type="text" :placeholder="apiKeyLabelName">
                    <label>{{ apiKeyLabelName }}</label>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-floating">
                    <input v-model="form.exchangeConfig.apiSecret" class="form-control" type="text" :placeholder="apiSecretLabelName">
                    <label>{{ apiSecretLabelName }}</label>
                  </div>
                </div>
              </div>
              <div v-if="selectedExchange.id == 'kucoin' || selectedExchange.id == 'bitget'" class="row mb-1 mt-2 justify-content-center">
                <div class="col-12">
                  <div class="form-floating">
                    <input v-model="form.exchangeConfig.passphrase" class="form-control" type="text" placeholder="API Key">
                    <label>API Passphrase</label>
                  </div>
                </div>
              </div>
              <div v-if="formValidations.apiKey" class="row mb-3 text-center justify-content-center">
                <div class="col-auto">
                  <div class="invalid-feedback d-block fs-10">{{ formValidations.apiKey }}</div>
                </div>
              </div>

              <div v-if="!loading" class="row mt-3">
                <div class="col-4 d-flex justify-content-start mb-2">
                  <button @click="cancel" type="submit" class="btn btn-secondary">Cancel</button>&nbsp;
                </div>
                <div class="col-8 d-flex justify-content-end mb-2">
                  <button @click="validateExchange" type="submit" class="btn btn-primary">Validate</button>
                </div>
              </div>
              <div v-if="loading" class="row text-center mt-3">
                <div class="col-12">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
              
            </div>
          </div>

        </div> <!-- col -->
      </div> <!-- row -->

      <div v-if="selectedExchange" class="row mb-1 justify-content-center text-left">
        <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">
          <div class="alert alert-primary" role="alert">
            Currently we support buy/sell operations on the LONG side (aka. no shorting & no leverage). We will add support for those soon.
            <br><br>
            With your API keys, SIGNUM can only perform trade operations, nothing else. Only give the permissions needed for trading, as shown below.
          </div>
        </div>
      </div> <!-- row -->

    </div> <!-- step 1 -->

    <div v-if="step == 2">
      <div class="row justify-content-center">
        <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">

          <div class="card mb-3">
            <div class="card-header">
              <div class="row flex-between-end">
                <div class="col-auto text-center">
                  <h5 class="mb-0" data-anchor="data-anchor">Create your Bot</h5>
                </div>
              </div>
            </div>
            <div class="card-body bg-body-tertiary text-start">
              <BotEditForm @save="onSave" :loading="loading" :exchangeId="form.exchangeId" :apiKey="form.exchangeConfig.apiKey" :apiSecret="form.exchangeConfig.apiSecret" :apiPassphrase="form.exchangeConfig.passphrase" />
            </div>
          </div>

        </div> <!-- col -->
      </div> <!-- row -->
    </div> <!-- step 2 -->

    <div v-if="step == 3">
      <div class="row justify-content-center">
        <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">

          <div class="card mb-3">
            <div class="card-header">
              <div class="row flex-between-end">
                <div class="col-auto text-center">
                  <h5 class="mb-0" data-anchor="data-anchor">Maximum Bots Reached</h5>
                </div>
              </div>
            </div>
            <div class="card-body bg-body-tertiary text-start">
              <p>You have reached the <span class="fw-bold">maximum amount of Bots</span> you can create with your subscription: <span class="fw-bold">{{ this.maxUserBots }}</span></p>
              <p>Please <a :href="getSupportUrl()" target="_blank"><span class="fw-bold">contact support</span></a> to get more 🤙🏻</p>
            </div>
          </div>

        </div> <!-- col -->
      </div> <!-- row -->
    </div> <!-- step 3 -->

    <!-- Bybit Explainers -->
    <div v-if="selectedExchange?.id == 'bybit'" class="row justify-content-center mt-3">
      <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">
        <div class="card mb-3">
          <div class="card-header">
            <div class="row flex-between-end">
              <div class="col-auto text-center">
                <h5 class="mb-0" data-anchor="data-anchor">Help for {{ selectedExchange.name }}</h5>
              </div>
            </div>
          </div>
          <div class="card-body bg-body-tertiary text-center">
            <div class="col-12 text-start">

              <div>
                <p>Go through <span class="fw-bold">all tabs</span> to finish your setup.</p>

                <ul class="nav nav-tabs" role="tablist">
                  <li class="nav-item"><a class="nav-link active" data-bs-toggle="tab" href="#tab-create-subaccount" role="tab" aria-controls="tab-create-subaccount" aria-selected="true">1. Create Subaccount</a></li>
                  <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" href="#tab-create-api" role="tab" aria-controls="tab-create-api" aria-selected="false">2. Create API</a></li>
                  <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" href="#tab-fund-account" role="tab" aria-controls="tab-fund-account" aria-selected="false">3. Fund Account</a></li>
                </ul>
                <div class="tab-content border-top-0 pt-3">
                  <div class="tab-pane fade text-center show active" id="tab-create-subaccount" role="tabpanel">
                    <p>
                      <span class="fw-bold">We require each Bot to run on its own Subaccount!</span><br>
                      This is best practice and avoids multiple issues later on. <a href="https://www.youtube.com/watch?v=0YMe4ZcTcuk" target="_blank"><span class="fw-bold">Watch this YouTube video</span></a> to understand why and thank us later 😊
                    </p>
                    <p>
                      If you are <span class="fw-bold">migrating from another tool</span> to SIGNUM, you can use the Subaccounts you already have, just <span class="fw-bold">create a new API Key</span> for SIGNUM based on the instructions below.
                    </p>
                    
                    <hr>
                    
                    <p>
                      Login to your <a href="https://www.bybit.com/en/sign-up?affiliate_id=64375&group_id=130061&group_type=1&ref_code=AUTOBOTS" target="_blank"><span class="fw-bold">Bybit account</span></a> and go to your <a href="https://www.bybit.com/app/user/sub-account?affiliate_id=64375&group_id=130061&group_type=1&ref_code=AUTOBOTS" target="_blank"><span class="fw-bold">Subaccounts</span></a>.<br>
                      Click on <span class="fw-bold">Create Subaccount</span>.<br>
                      Choose <span class="fw-bold">Standard Subaccount</span>.
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/bybit_subaccount_step1.jpg" />
                    
                    <p>
                      Give it a name and choose the settings as shown.<br>
                      Click <span class="fw-bold">Confirm</span>.
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/bybit_subaccount_step2_v2.jpg" />

                    <p>Go to the next tab <span class="fw-bold">Create API</span> to create your API credentials...</p>
                    
                  </div>
                  <div class="tab-pane fade text-center" id="tab-create-api" role="tabpanel">
                    <p>For your Subaccount, locate the <span class="fw-bold">API</span> column and click on <span class="fw-bold">Management</span>.</p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/bybit_subaccount_step3_v2.jpg" />

                    <p>
                      Now create a new API Key for the Subaccount by clicking on the <span class="fw-bold">Create New Key</span> button.
                      <br>
                      Select the <span class="fw-bold">System-genearted API Keys</span> option.</p>
                    <!-- <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/bybit_apikey_create.jpg" /> -->
                    <vue-image-zoomer img-class="img-fluid rounded mx-auto d-block mb-3" :click-zoom="true" :zoom-amount="2" :show-message-touch="false" :tap-to-close="true" regular="/exchanges/explainers/bybit_apikey_create.jpg" zoom="/exchanges/explainers/bybit_apikey_create.jpg" />
                    
                    <p>
                      Choose the <span class="fw-bold">settings</span> as shown below.<br>
                      By choosing SIGNUM from the dropdown, {{ selectedExchange.name }} already automatically restricts usage to our IP address (18.199.127.118) only, for extra security.
                    </p>
                    <!-- <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/bybit_apikey_settings_v2.jpg" /> -->
                    <vue-image-zoomer img-class="img-fluid rounded mx-auto d-block mb-3" :click-zoom="true" :zoom-amount="1.5" :show-message-touch="false" :tap-to-close="true" regular="/exchanges/explainers/bybit_apikey_settings_v2.jpg" zoom="/exchanges/explainers/bybit_apikey_settings_v2.jpg" />
                    
                    <p>
                      Now paste the <span class="fw-bold">API Key & API Secret</span> into SIGNUM.<br>
                      Click <span class="fw-bold">Validate</span>.
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/bybit_paste_into_signum.jpg" />

                    <p>
                      Ensure the new API Key you have created is <span class="fw-bold">Permanent</span> otherwise it will expire in a few months! Scroll to the right to find that column.
                    </p>
                    <!-- <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/bybit_apikey_permanent.jpg" /> -->
                    <vue-image-zoomer img-class="img-fluid rounded mx-auto d-block mb-3" :click-zoom="true" :zoom-amount="2" :show-message-touch="false" :tap-to-close="true" regular="/exchanges/explainers/bybit_apikey_permanent.jpg" zoom="/exchanges/explainers/bybit_apikey_permanent.jpg" />

                    <p>
                      ⚠️ <span class="fw-bold">Approve all terms & conditions</span> Bybit requires for your trading pair ⚠️<br>
                      <span class="fw-bold">Select the trading pair</span> in the <a href="https://www.bybit.com/en/trade/spot/SUI/USDT?affiliate_id=64375&group_id=130061&group_type=1&ref_code=AUTOBOTS" target="_blank">trading interface</a>, and wait a few seconds to see if Bybit shows a popup you must approve before being able to trade that pair.<br>
                      Once approved by you, it's valid for your entire Bybit account and all Subaccounts (nice).<br>
                      <br>
                      If you don't do this, your API trades will produce errors.<br>
                      So far we have seen this only for <span class="fw-bold">SUI</span> trading pairs, but it can appear for more pairs in the future, so please always perform this simple step to be sure.<br>
                    </p>
                    <vue-image-zoomer img-class="img-fluid rounded mx-auto d-block mb-3" :click-zoom="true" :zoom-amount="2" :show-message-touch="false" :tap-to-close="true" regular="/exchanges/explainers/bybit_approve_tcs.jpg" zoom="/exchanges/explainers/bybit_approve_tcs.jpg" />

                    <p>Go to the next tab <span class="fw-bold">Fund Account</span> to fund your Subaccount...</p>
                  </div>
                  <div class="tab-pane fade text-center" id="tab-fund-account" role="tabpanel">
                    <p>
                      To send funds to your Subaccount, go to your <a href="https://www.bybit.com/app/user/sub-account?affiliate_id=64375&group_id=130061&group_type=1&ref_code=AUTOBOTS" target="_blank"><span class="fw-bold">Subaccounts</span></a>.<br>
                    </p>
                    <p>
                      Click on <span class="fw-bold">Transfer</span> for the Subaccount in question.<br>
                      Select the <span class="fw-bold">From</span> account where your funds are now.<br>
                      Choose where to send the funds <span class="fw-bold">To</span> and make sure you choose the <span class="fw-bold">Unified Trading</span> account.<br>
                      Click <span class="fw-bold">Confirm</span>.
                    </p>
                    <!-- <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/bybit_fund_account_v2.jpg" /> -->
                    <vue-image-zoomer img-class="img-fluid rounded mx-auto d-block mb-3" :click-zoom="true" :zoom-amount="2" :show-message-touch="false" :tap-to-close="true" regular="/exchanges/explainers/bybit_fund_account_v2.jpg" zoom="/exchanges/explainers/bybit_fund_account_v2.jpg" />

                    <p><span class="fw-bold">Done :)</span></p>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        
      </div>
    </div> <!-- row -->

    <!-- Kraken Explainers -->
    <div v-if="selectedExchange?.id == 'kraken'" class="row justify-content-center mt-3">
      <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">
        <div class="card mb-3">
          <div class="card-header">
            <div class="row flex-between-end">
              <div class="col-auto text-center">
                <h5 class="mb-0" data-anchor="data-anchor">Help for {{ selectedExchange.name }}</h5>
              </div>
            </div>
          </div>
          <div class="card-body bg-body-tertiary text-center">
            <div class="col-12 text-start">

              <div>
                <p>Go through <span class="fw-bold">all tabs</span> to finish your setup.</p>

                <ul class="nav nav-tabs" role="tablist">
                  <li class="nav-item"><a class="nav-link active" data-bs-toggle="tab" href="#tab-create-subaccount" role="tab" aria-controls="tab-create-subaccount" aria-selected="true">1. Create Subaccount</a></li>
                  <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" href="#tab-create-api" role="tab" aria-controls="tab-create-api" aria-selected="false">2. Create API</a></li>
                  <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" href="#tab-fund-account" role="tab" aria-controls="tab-fund-account" aria-selected="false">3. Fund Account</a></li>
                </ul>
                <div class="tab-content border-top-0 pt-3">
                  <div class="tab-pane fade text-center show active" id="tab-create-subaccount" role="tabpanel" aria-labelledby="text-tab">
                    <p>
                      <span class="fw-bold">We require each Bot to run on its own Subaccount!</span><br>
                      This is best practice and avoids multiple issues later on. <a href="https://www.youtube.com/watch?v=0YMe4ZcTcuk" target="_blank"><span class="fw-bold">Watch this YouTube video</span></a> to understand why and thank us later 😊<br>
                      <br>
                      <span class="fw-bold">Kraken does NOT support Subaccounts</span>.<br>
                      They recommend creating new accounts with different email addresses. <span class="fw-bold">If you're using Gmail</span>, you can use this trick to create infinite aliases without any setup: <a href="https://www.youtube.com/shorts/jTxRCEpUgt0" target="_blank"><span class="fw-bold">YouTube Video</span></a>
                    </p>
                    <p>
                      If you are <span class="fw-bold">migrating from another tool</span> to SIGNUM, you can use the accounts you already have, just <span class="fw-bold">create a new API Key</span> for SIGNUM based on the instructions below :)
                    </p>
                    <hr>
                    <p>
                      Login to your <a href="https://pro.kraken.com" target="_blank"><span class="fw-bold">Kraken PRO</span></a> account.
                    </p>
                    <p>Go to the next tab <span class="fw-bold">Create API</span> to create your API credentials...</p>
                  </div>
                  <div class="tab-pane fade text-center" id="tab-create-api" role="tabpanel">
                    <p>
                      Click on <span class="fw-bold">Settings</span>.
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/kraken_settings.jpg" />

                    <p>Click on the <span class="fw-bold">API</span> tab and then click on <span class="fw-bold">Create API key</span>.</p>
                    <!-- <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/kraken_apikey_create.jpg" /> -->
                    <vue-image-zoomer img-class="img-fluid rounded mx-auto d-block mb-3" :click-zoom="true" :zoom-amount="1.5" :show-message-touch="false" :tap-to-close="true" regular="/exchanges/explainers/kraken_apikey_create.jpg" zoom="/exchanges/explainers/kraken_apikey_create.jpg" />

                    <p>
                      Give it a <span class="fw-bold">name</span> and select the <span class="fw-bold">permissions</span> as shown.<br>
                      Enable <span class="fw-bold">IP address restrictions</span> and add SIGNUM's IP address, for additional security: <span class="fw-bold">18.199.127.118</span><br>
                      Click <span class="fw-bold">Generate key</span>.
                    </p>
                    <!-- <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/kraken_apikey_settings.jpg" /> -->
                    <vue-image-zoomer img-class="img-fluid rounded mx-auto d-block mb-3" :click-zoom="true" :zoom-amount="1.5" :show-message-touch="false" :tap-to-close="true" regular="/exchanges/explainers/kraken_apikey_settings.jpg" zoom="/exchanges/explainers/kraken_apikey_settings.jpg" />
                    
                    <p>
                      Now paste the <span class="fw-bold">API Key & API Secret</span> into SIGNUM.<br>
                      Click <span class="fw-bold">Validate</span>.
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/kraken_paste_into_signum.jpg" />

                    <p>Go to the next tab <span class="fw-bold">Fund Account</span> to fund your account...</p>
                  </div>
                  <div class="tab-pane fade text-center" id="tab-fund-account" role="tabpanel">
                    <p>
                      Kraken does not have diffrent account types, so as long as you have funds in your <a href="https://pro.kraken.com/app/portfolio/spot" target="_blank"><span class="fw-bold">Portfolio</span></a>, you are good to go.
                    </p>
                    <p><span class="fw-bold">Done :)</span></p>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        
      </div>
    </div> <!-- row -->

    <!-- Binance Explainers -->
    <div v-if="selectedExchange?.id == 'binance'" class="row justify-content-center mt-3">
      <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">
        <div class="card mb-3">
          <div class="card-header">
            <div class="row flex-between-end">
              <div class="col-auto text-center">
                <h5 class="mb-0" data-anchor="data-anchor">Help for {{ selectedExchange.name }}</h5>
              </div>
            </div>
          </div>
          <div class="card-body bg-body-tertiary text-center">
            <div class="col-12 text-start">

              <div>
                <p>Go through <span class="fw-bold">all tabs</span> to finish your setup.</p>

                <ul class="nav nav-tabs" role="tablist">
                  <li class="nav-item"><a class="nav-link active" data-bs-toggle="tab" href="#tab-create-subaccount" role="tab" aria-controls="tab-create-subaccount" aria-selected="true">1. Create Subaccount</a></li>
                  <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" href="#tab-create-api" role="tab" aria-controls="tab-create-api" aria-selected="false">2. Create API</a></li>
                  <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" href="#tab-fund-account" role="tab" aria-controls="tab-fund-account" aria-selected="false">3. Fund Account</a></li>
                </ul>
                <div class="tab-content border-top-0 pt-3">
                  <div class="tab-pane fade text-center show active" id="tab-create-subaccount" role="tabpanel">
                    <p>
                      <span class="fw-bold">We require each Bot to run on its own Subaccount!</span><br>
                      This is best practice and avoids multiple issues later on. <a href="https://www.youtube.com/watch?v=0YMe4ZcTcuk" target="_blank"><span class="fw-bold">Watch this YouTube video</span></a> to understand why and thank us later 😊
                    </p>
                    <p>
                      If you are <span class="fw-bold">migrating from another tool</span> to SIGNUM, you can use the accounts you already have, just <span class="fw-bold">create a new API Key</span> for SIGNUM based on the instructions below :)
                    </p>
                    
                    <hr>

                    <p>
                      Login to your <a href="https://www.binance.com" target="_blank"><span class="fw-bold">Binance</span></a> account and go to your <a href="https://www.binance.com/en/my/sub-account/account-management" target="_blank"><span class="fw-bold">Sub Accounts</span></a>.<br>
                      Click on <span class="fw-bold">Create Sub Account</span>
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/binance_create_subaccount_button.jpg" />

                    <p>
                      Select the <span class="fw-bold">settings</span> as shown and give it a good name.<br>
                      Click <span class="fw-bold">Create Sub Account</span>.
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/binance_create_subaccount_settings.jpg" />
                    
                    <p>
                      Binance will now create a virtual email for your Sub Account.<br>
                      In this example it's: <span class="fw-bold">strategybtc_virtual@hof0hvm4noemail.com</span><br>
                      This is how you identify the Sub Account from now on.
                    </p>

                    <p>Go to the next tab <span class="fw-bold">Create API</span> to create your API credentials...</p>
                  </div>
                  <div class="tab-pane fade text-center" id="tab-create-api" role="tabpanel">
                    <p>To create your API Keys, go to <a href="https://www.binance.com/en/my/sub-account/api-management" target="_blank"><span class="fw-bold">API Management</span></a> (left side menu).</p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/binance_api_management_menu.jpg" />

                    <p>Click on <span class="fw-bold">Create API</span>.</p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/binance_create_api_button.jpg" />

                    <p>
                      Choose the <span class="fw-bold">System generated</span> API Key type.<br>
                      Click <span class="fw-bold">Next</span>.
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/binance_api_type.jpg" />

                    <p>Choose the <span class="fw-bold">Sub Account</span> for which you want to generate the API Keys for.</p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/binance_api_choose_subaccount.jpg" />

                    <p>
                      Type <span class="fw-bold">SIGNUM</span> in the <span class="fw-bold">API Key Label</span> field.<br>
                      Click <span class="fw-bold">Next</span>.<br>
                      Do the <span class="fw-bold">2FA</span> as needed.
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/binance_api_key_label.jpg" />

                    <p>
                      Now add SIGNUM's <span class="fw-bold">IP Address (18.199.127.118)</span>, otherwise Binance won't allow you to enable "Spot & Margin Trading".<br>
                      Click <span class="fw-bold">Confirm</span>.
                    </p>
                    <!-- <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/binance_add_ip_address.jpg" /> -->
                    <vue-image-zoomer img-class="img-fluid rounded mx-auto d-block mb-3" :click-zoom="true" :zoom-amount="2" :show-message-touch="false" :tap-to-close="true" regular="/exchanges/explainers/binance_add_ip_address.jpg" zoom="/exchanges/explainers/binance_add_ip_address.jpg" />

                    <p>
                      Now enable <span class="fw-bold">Spot & Margin Trading</span>.<br>
                      Click <span class="fw-bold">Save</span> and do the 2FA process as needed.
                    </p>
                    <!-- <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/binance_enable_spot_trading.jpg" /> -->
                    <vue-image-zoomer img-class="img-fluid rounded mx-auto d-block mb-3" :click-zoom="true" :zoom-amount="2" :show-message-touch="false" :tap-to-close="true" regular="/exchanges/explainers/binance_enable_spot_trading.jpg" zoom="/exchanges/explainers/binance_enable_spot_trading.jpg" />

                    <p>
                      Copy the <span class="fw-bold">API Key & Secret Key</span> into SIGNUM and click <span class="fw-bold">Validate</span>.
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/binance_copy_keys.jpg" />
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/binance_paste_into_signum.jpg" />

                    <p>Go to the next tab <span class="fw-bold">Fund Account</span> to fund your Subaccount...</p>
                  </div>
                  <div class="tab-pane fade text-center" id="tab-fund-account" role="tabpanel">
                    <p>
                      To send funds to your Sub Account, go to your Sub Accounts <a href="https://www.binance.com/en/my/sub-account/account-management" target="_blank"><span class="fw-bold">Account Management</span></a> (left side menu).<br>
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/binance_account_management.jpg" />

                    <p>
                     Click on the <span class="fw-bold">three dots</span> and select <span class="fw-bold">Transfer In</span> for the Sub Account in question.
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/binance_transfer_in.jpg" />

                    <p>
                     Select the <span class="fw-bold">Transfer From</span> account where your funds are now.<br>
                     Select the <span class="fw-bold">Sub Account</span> to <span class="fw-bold">Transfer To</span> and choose <span class="fw-bold">Spot</span>.<br>
                     Choose the <span class="fw-bold">Coin</span> and the <span class="fw-bold">Amount</span> as needed.<br>
                     Click <span class="fw-bold">Confirm</span>.
                    </p>
                    <!-- <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/binance_transfer_funds.jpg" /> -->
                    <vue-image-zoomer img-class="img-fluid rounded mx-auto d-block mb-3" :click-zoom="true" :zoom-amount="1.5" :show-message-touch="false" :tap-to-close="true" regular="/exchanges/explainers/binance_transfer_funds.jpg" zoom="/exchanges/explainers/binance_transfer_funds.jpg" />

                    <p>
                      In SIGNUM, you will be able to see those funds in your Bot settings after you have created it.
                    </p>

                    <p><span class="fw-bold">Done :)</span></p>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        
      </div>
    </div> <!-- row -->

    <!-- KuCoin Explainers -->
    <div v-if="selectedExchange?.id == 'kucoin'" class="row justify-content-center mt-3">
      <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">
        <div class="card mb-3">
          <div class="card-header">
            <div class="row flex-between-end">
              <div class="col-auto text-center">
                <h5 class="mb-0" data-anchor="data-anchor">Help for {{ selectedExchange.name }}</h5>
              </div>
            </div>
          </div>
          <div class="card-body bg-body-tertiary text-center">
            <div class="col-12 text-start">

              <div>
                <p>Go through <span class="fw-bold">all tabs</span> to finish your setup.</p>

                <ul class="nav nav-tabs" role="tablist">
                  <li class="nav-item"><a class="nav-link active" data-bs-toggle="tab" href="#tab-create-subaccount" role="tab" aria-controls="tab-create-subaccount" aria-selected="true">1. Create Subaccount</a></li>
                  <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" href="#tab-create-api" role="tab" aria-controls="tab-create-api" aria-selected="false">2. Create API</a></li>
                  <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" href="#tab-fund-account" role="tab" aria-controls="tab-fund-account" aria-selected="false">3. Fund Account</a></li>
                </ul>
                <div class="tab-content border-top-0 pt-3">
                  <div class="tab-pane fade text-center show active" id="tab-create-subaccount" role="tabpanel">
                    <p>
                      <span class="fw-bold">We require each Bot to run on its own Subaccount!</span><br>
                      This is best practice and avoids multiple issues later on. <a href="https://www.youtube.com/watch?v=0YMe4ZcTcuk" target="_blank"><span class="fw-bold">Watch this YouTube video</span></a> to understand why and thank us later 😊
                    </p>
                    <p>
                      If you are <span class="fw-bold">migrating from another tool</span> to SIGNUM, you can use the accounts you already have, just <span class="fw-bold">create a new API Key</span> for SIGNUM based on the instructions below :)
                    </p>
                    
                    <hr>

                    <p>
                      Login to your <a href="https://www.kucoin.com/" target="_blank"><span class="fw-bold">KuCoin account</span></a> and go to your <a href="https://www.kucoin.com/account/sub" target="_blank"><span class="fw-bold">Sub-Accounts</span></a>.<br>
                      Click on <span class="fw-bold">Create Sub-Account</span>.
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/kucoin_create_subaccount_button.jpg" />

                    <p>
                      Select the <span class="fw-bold">settings</span> as shown and give it a good name.<br>
                      Choose any <span class="fw-bold">Login Password</span>, you won't need it later anyhow.<br>
                      Click <span class="fw-bold">Save</span>.<br>
                      Do the <span class="fw-bold">2FA</span> as needed.
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/kucoin_create_subaccount_form.jpg" />
                    
                    <p>Go to the next tab <span class="fw-bold">Create API</span> to create your API credentials...</p>
                  </div>
                  <div class="tab-pane fade text-center" id="tab-create-api" role="tabpanel">
                    <p>
                      Now let's create the API Keys.<br>
                      For that click on <span class="fw-bold">Settings</span> (or the three dots) and then on <span class="fw-bold">View API</span> for the Sub-Account in question.
                    </p>
                    <!-- <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/kucoin_view_api_v2.jpg" /> -->
                    <vue-image-zoomer img-class="img-fluid rounded mx-auto d-block mb-3" :click-zoom="true" :zoom-amount="2" :show-message-touch="false" :tap-to-close="true" regular="/exchanges/explainers/kucoin_view_api_v2.jpg" zoom="/exchanges/explainers/kucoin_view_api_v2.jpg" />

                    <p>Click on <span class="fw-bold">Create API</span>.</p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/kucoin_create_api_button_v2.jpg" />

                    <p>
                      Choose the <span class="fw-bold">settings</span> as shown.<br>
                      Choose an <span class="fw-bold">API Passphrase</span> and <span class="fw-bold">remember</span> it. You will need it for the SIGNUM setup.
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/kucoin_create_api_form_1.jpg" />

                    <p>
                      Allow SIGNUM's IP address, for additional security: <span class="fw-bold">18.199.127.118</span><br>
                      Copy/Paste the IP and then click <span class="fw-bold">Add</span>.<br>
                      Make sure the IP appears like the arrow shows and click <span class="fw-bold">Next</span>.
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/kucoin_create_api_form_2.jpg" />
                    
                    <p>
                      Now paste your new <span class="fw-bold">API Key, API Secret & Passphrase</span> (the one you defined above) into SIGNUM.<br>
                      Click <span class="fw-bold">Validate</span>.
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/kucoin_paste_into_signum.jpg" />

                    <p>Go to the next tab <span class="fw-bold">Fund Account</span> to fund your Subaccount...</p>
                  </div>
                  <div class="tab-pane fade text-center" id="tab-fund-account" role="tabpanel">
                    <p>
                      To send funds to your Subaccount, go to your <a href="https://www.kucoin.com/account/sub" target="_blank"><span class="fw-bold">Sub-Accounts</span></a>.
                    </p>
                    <p>
                      Click on <span class="fw-bold">Transfer</span> for the Sub-Account in question.<br>
                      Select the <span class="fw-bold">From</span> account where your funds are now.<br>
                      Select the <span class="fw-bold">Trading Account</span> as your <span class="fw-bold">To</span> (not the Funding Account)!<br>
                      Choose the <span class="fw-bold">Asset</span> and the <span class="fw-bold">Amount</span>.<br>
                      Click <span class="fw-bold">Confirm</span>.
                    </p>
                    <!-- <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/kucoin_transfer_funds.jpg" /> -->
                    <vue-image-zoomer img-class="img-fluid rounded mx-auto d-block mb-3" :click-zoom="true" :zoom-amount="1.5" :show-message-touch="false" :tap-to-close="true" regular="/exchanges/explainers/kucoin_transfer_funds.jpg" zoom="/exchanges/explainers/kucoin_transfer_funds.jpg" />

                    <p><span class="fw-bold">Done :)</span></p>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        
      </div>
    </div> <!-- row -->

    <!-- Coinbase Explainers -->
    <div v-if="selectedExchange?.id == 'coinbase'" class="row justify-content-center mt-3">
      <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">
        <div class="card mb-3">
          <div class="card-header">
            <div class="row flex-between-end">
              <div class="col-auto text-center">
                <h5 class="mb-0" data-anchor="data-anchor">Help for {{ selectedExchange.name }}</h5>
              </div>
            </div>
          </div>
          <div class="card-body bg-body-tertiary text-center">
            <div class="col-12 text-start">

              <div>
                <p>Go through <span class="fw-bold">all tabs</span> to finish your setup.</p>

                <ul class="nav nav-tabs" role="tablist">
                  <li class="nav-item"><a class="nav-link active" data-bs-toggle="tab" href="#tab-create-subaccount" role="tab" aria-controls="tab-create-subaccount" aria-selected="true">1. Create Portfolio</a></li>
                  <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" href="#tab-create-api" role="tab" aria-controls="tab-create-api" aria-selected="false">2. Create API</a></li>
                  <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" href="#tab-fund-account" role="tab" aria-controls="tab-fund-account" aria-selected="false">3. Fund Portfolio</a></li>
                </ul>
                <div class="tab-content border-top-0 pt-3">
                  <div class="tab-pane fade text-center show active" id="tab-create-subaccount" role="tabpanel">
                    <p>
                      <span class="fw-bold">We require each Bot to run on its own Portfolio (aka. Subaccount).</span><br>
                      This is best practice and avoids multiple issues later on. <a href="https://www.youtube.com/watch?v=0YMe4ZcTcuk" target="_blank"><span class="fw-bold">Watch this YouTube video</span></a> to understand why and thank us later 😊
                    </p>
                    <p>
                      If you are <span class="fw-bold">migrating from another tool</span> to SIGNUM, you can use the Portfolios you already have, just <span class="fw-bold">create a new API Key</span> for SIGNUM based on the instructions below.
                    </p>
                    
                    <hr>
                    
                    <p>
                      Login to your <a href="https://www.coinbase.com/" target="_blank"><span class="fw-bold">Coinbase account</span></a> and go to your <a href="https://www.coinbase.com/advanced-portfolio" target="_blank"><span class="fw-bold">Portfolios</span></a>.<br>
                      If you don't see that feature, turn ON <span class="fw-bold">Advanced Mode</span> in the bottom left corner.
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/coinbase_turn_on_advanced_mode.jpg" />

                    <p>
                      Click on <span class="fw-bold">New portfolio</span>.<br>
                      Give it a <span class="fw-bold">name</span>.<br>
                      Click <span class="fw-bold">Create</span>.<br>
                    </p>
                    <!-- <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/coinbase_create_portfolio.jpg" /> -->
                    <vue-image-zoomer img-class="img-fluid rounded mx-auto d-block mb-3" :click-zoom="true" :zoom-amount="1.5" :show-message-touch="false" :tap-to-close="true" regular="/exchanges/explainers/coinbase_create_portfolio.jpg" zoom="/exchanges/explainers/coinbase_create_portfolio.jpg" />

                    <p>Go to the next tab <span class="fw-bold">Create API</span> to create your API credentials...</p>
                    
                  </div>
                  <div class="tab-pane fade text-center" id="tab-create-api" role="tabpanel">
                    <p>
                      Go to the <a href="https://www.coinbase.com/settings/api" target="_blank"><span class="fw-bold">API</span></a> page.<br>
                      Click on <span class="fw-bold">Create API key</span>
                    </p>
                    <!-- <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/coinbase_go_to_api_page.jpg" /> -->
                    <vue-image-zoomer img-class="img-fluid rounded mx-auto d-block mb-3" :click-zoom="true" :zoom-amount="1.5" :show-message-touch="false" :tap-to-close="true" regular="/exchanges/explainers/coinbase_go_to_api_page.jpg" zoom="/exchanges/explainers/coinbase_go_to_api_page.jpg" />

                    <p>
                      Fill in the form as shown below.<br>
                      For the <span class="fw-bold">Portfolio</span> choose the one you have just created before.<br>
                      Paste our IP address into the <span class="fw-bold">IP whitelist</span> field (extra security): <span class="fw-bold">18.199.127.118</span><br>
                      Click <span class="fw-bold">Create & download</span>. Do the 2FA as needed.<br>
                      <i>You can delete the .json file that Coinabse gave you.</i>
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/coinbase_create_api_key.jpg" />

                    <p>
                      If you are having issues creating the <span class="fw-bold">API Keys</span>, the most common cause is the <span class="fw-bold">Security Prompt</span>, which is not opening up properly - it has a bug.<br>
                      Go to the <a class="fw-bold" href="https://accounts.coinbase.com/security/settings" target="_blank">Security</a> settings and <span class="fw-bold">turn it OFF</span> and try creating the API key again.
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/coinbase_turn_off_security_prompt.jpg" />
                    
                    <p>
                      Now copy the <span class="fw-bold">API key name & Private key</span>, scroll up and paste them into the form.<br>
                      Click <span class="fw-bold">Validate</span>.
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/coinbase_copy_keys.jpg" />

                    <p>Go to the next tab <span class="fw-bold">Fund Portfolio</span> to put funds on your Portfolio...</p>
                  </div>
                  <div class="tab-pane fade text-center" id="tab-fund-account" role="tabpanel">
                    <p>
                      To send funds to your Portfolio, go to your <a href="https://www.coinbase.com/advanced-portfolio" target="_blank"><span class="fw-bold">Portfolios</span></a> and click on the one you need.<br>
                      Click on <span class="fw-bold">Transfer in</span> and follow the flow.
                    </p>
                    <!-- <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/coinbase_fund_account.jpg" /> -->
                    <vue-image-zoomer img-class="img-fluid rounded mx-auto d-block mb-3" :click-zoom="true" :zoom-amount="1.5" :show-message-touch="false" :tap-to-close="true" regular="/exchanges/explainers/coinbase_fund_account.jpg" zoom="/exchanges/explainers/coinbase_fund_account.jpg" />

                    <p><span class="fw-bold">Done :)</span></p>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        
      </div>
    </div> <!-- row -->

    <!-- MEXC Explainers -->
    <div v-if="selectedExchange?.id == 'mexc'" class="row justify-content-center mt-3">
      <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">
        <div class="card mb-3">
          <div class="card-header">
            <div class="row flex-between-end">
              <div class="col-auto text-center">
                <h5 class="mb-0" data-anchor="data-anchor">Help for {{ selectedExchange.name }}</h5>
              </div>
            </div>
          </div>
          <div class="card-body bg-body-tertiary text-center">
            <div class="col-12 text-start">

              <div>
                <p>Go through <span class="fw-bold">all tabs</span> to finish your setup.</p>

                <ul class="nav nav-tabs" role="tablist">
                  <li class="nav-item"><a class="nav-link active" data-bs-toggle="tab" href="#tab-create-subaccount" role="tab" aria-controls="tab-create-subaccount" aria-selected="true">1. Create Subaccount</a></li>
                  <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" href="#tab-create-api" role="tab" aria-controls="tab-create-api" aria-selected="false">2. Create API</a></li>
                  <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" href="#tab-fund-account" role="tab" aria-controls="tab-fund-account" aria-selected="false">3. Fund Account</a></li>
                </ul>
                <div class="tab-content border-top-0 pt-3">
                  <div class="tab-pane fade text-center show active" id="tab-create-subaccount" role="tabpanel">
                    <p>
                      <span class="fw-bold">We require each Bot to run on its own Subaccount!</span><br>
                      This is best practice and avoids multiple issues later on. <a href="https://www.youtube.com/watch?v=0YMe4ZcTcuk" target="_blank"><span class="fw-bold">Watch this YouTube video</span></a> to understand why and thank us later 😊
                    </p>
                    <p>
                      If you are <span class="fw-bold">migrating from another tool</span> to SIGNUM, you can use the accounts you already have, just <span class="fw-bold">create a new API Key</span> for SIGNUM based on the instructions below :)
                    </p>
                    
                    <hr>

                    <p>
                      Login to your <a href="https://www.mexc.com/register?inviteCode=mexc-12TvMD" target="_blank"><span class="fw-bold">MEXC</span></a> account and go to your <a href="https://www.mexc.com/user/sub-account?inviteCode=mexc-12TvMD" target="_blank"><span class="fw-bold">Sub-Account Management</span></a>.<br>
                      Click on <span class="fw-bold">Create a Sub-Account</span>.
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/mexc_create_subaccount_button.jpg" />

                    <p>
                      Pick this <span class="fw-bold">Subaccount type</span>.
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/mexc_subaccount_type.jpg" />

                    <p>
                      Give you Subaccount a good and unique <span class="fw-bold">Username</span><br>
                      <span class="fw-bold">ATTENTION:</span> MEXC requires you to have a <span class="fw-bold">NUMBER</span> (e.g. 1, 2, 3) in your username for some reason.<br>
                      Click <span class="fw-bold">Next Step</span>.
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/mexc_create_subaccount.jpg" />
                    <p>
                      Do 2FA as required.<br>
                      Remember the name you have given this SubAccount as this is how you identify the Subaccount from now on.
                    </p>

                    <p>Go to the next tab <span class="fw-bold">Create API</span> to create your API credentials...</p>
                  </div>
                  <div class="tab-pane fade text-center" id="tab-create-api" role="tabpanel">
                    <p>To create your API Keys, go to <a href="https://www.mexc.com/user/sub-account?tab=openapi?inviteCode=mexc-12TvMD" target="_blank"><span class="fw-bold">API</span></a> settings.</p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/mexc_api_settings_menu.jpg" />

                    <p>Click on <span class="fw-bold">Create an API</span>.</p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/mexc_create_an_api_button.jpg" />

                    <p>
                      Choose the settings as shown below.<br>
                      <span class="fw-bold">Select your Subaccount</span> from the dropdown.<br>
                      To make your API keys permanent, you must paste our IP address: <span class="fw-bold">18.199.127.118</span><br>
                      Do 2FA as required.
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/mexc_create_api_keys.jpg" />

                    <p>
                      Now copy the <span class="fw-bold">Access Key & Secret Key</span>, scroll up and paste them into the form.<br>
                      Click <span class="fw-bold">Validate</span>.
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/mexc_copy_keys_to_signum.jpg" />

                    <p>Go to the next tab <span class="fw-bold">Fund Account</span> to fund your Subaccount...</p>
                  </div>
                  <div class="tab-pane fade text-center" id="tab-fund-account" role="tabpanel">
                    <p>
                      To send funds to your Sub Account, go to <a href="https://www.mexc.com/user/sub-account?tab=assets&inviteCode=mexc-12TvMD" target="_blank"><span class="fw-bold">Asset Management</span></a>.<br>
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/mexc_asset_management.jpg" />

                    <p>
                     Click on <span class="fw-bold">Transfer</span> for the Subaccount in question.
                    </p>
                    <!-- <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/mexc_click_transfer.jpg" /> -->
                    <vue-image-zoomer img-class="img-fluid rounded mx-auto d-block mb-3" :click-zoom="true" :zoom-amount="1.5" :show-message-touch="false" :tap-to-close="true" regular="/exchanges/explainers/mexc_click_transfer.jpg" zoom="/exchanges/explainers/mexc_click_transfer.jpg" />

                    <p>
                     Select the <span class="fw-bold">Sending Account</span> account where your funds are now.<br>
                     Select the <span class="fw-bold">Receiving Account</span> (aka. your Subaccount) and choose <span class="fw-bold">Spot</span>.<br>
                     Choose the <span class="fw-bold">Coin</span> and the <span class="fw-bold">Amount</span> as needed.<br>
                     Click <span class="fw-bold">Transfer</span>.
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/mexc_transfer_funds_form.jpg" />
                    <p>
                      In SIGNUM, you will be able to see those funds in your Bot settings after you have created it.
                    </p>

                    <p><span class="fw-bold">Done :)</span></p>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        
      </div>
    </div> <!-- row -->

    <!-- Bitget Explainers -->
    <div v-if="selectedExchange?.id == 'bitget'" class="row justify-content-center mt-3">
      <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">
        <div class="card mb-3">
          <div class="card-header">
            <div class="row flex-between-end">
              <div class="col-auto text-center">
                <h5 class="mb-0" data-anchor="data-anchor">Help for {{ selectedExchange.name }}</h5>
              </div>
            </div>
          </div>
          <div class="card-body bg-body-tertiary text-center">
            <div class="col-12 text-start">

              <div>
                <p>Go through <span class="fw-bold">all tabs</span> to finish your setup.</p>

                <ul class="nav nav-tabs" role="tablist">
                  <li class="nav-item"><a class="nav-link active" data-bs-toggle="tab" href="#tab-create-subaccount" role="tab" aria-controls="tab-create-subaccount" aria-selected="true">1. Create Subaccount</a></li>
                  <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" href="#tab-create-api" role="tab" aria-controls="tab-create-api" aria-selected="false">2. Create API</a></li>
                  <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" href="#tab-fund-account" role="tab" aria-controls="tab-fund-account" aria-selected="false">3. Fund Account</a></li>
                </ul>
                <div class="tab-content border-top-0 pt-3">
                  <div class="tab-pane fade text-center show active" id="tab-create-subaccount" role="tabpanel">
                    <p>
                      <span class="fw-bold">We require each Bot to run on its own Subaccount!</span><br>
                      This is best practice and avoids multiple issues later on. <a href="https://www.youtube.com/watch?v=0YMe4ZcTcuk" target="_blank"><span class="fw-bold">Watch this YouTube video</span></a> to understand why and thank us later 😊
                    </p>
                    <p>
                      If you are <span class="fw-bold">migrating from another tool</span> to SIGNUM, you can use the accounts you already have, just <span class="fw-bold">create a new API Key</span> for SIGNUM based on the instructions below :)
                    </p>
                    
                    <hr>

                    <p>
                      Login to your <a href="https://www.bitget.com/" target="_blank"><span class="fw-bold">Bitget account</span></a> and go to your <a href="https://www.bitget.com/account/sub-account" target="_blank"><span class="fw-bold">Sub-accounts</span></a>.<br>
                      Click on <span class="fw-bold">Create</span>.
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/bitget_create_subaccount_button.jpg" />

                    <p>
                      Select the <span class="fw-bold">Sub-account type</span> as shown.<br>
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/bitget_choose_subaccount_type.jpg" />

                    <p>
                      Enter a <span class="fw-bold">Sub-account name</span>.<br>
                      Click <span class="fw-bold">Create</span>.<br>
                      Do the <span class="fw-bold">2FA</span> as needed.
                    </p>
                    <!-- <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/bitget_subaccount_form.jpg" /> -->
                    <vue-image-zoomer img-class="img-fluid rounded mx-auto d-block mb-3" :click-zoom="true" :zoom-amount="1.5" :show-message-touch="false" :tap-to-close="true" regular="/exchanges/explainers/bitget_subaccount_form.jpg" zoom="/exchanges/explainers/bitget_subaccount_form.jpg" />

                    <p>
                      <span class="fw-bold">RELOAD</span> to see the new Sub-account appear in the Sub-account management list.<br>
                    </p>
                    <!-- <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/bitget_find_your_subaccount.jpg" /> -->
                    <vue-image-zoomer img-class="img-fluid rounded mx-auto d-block mb-3" :click-zoom="true" :zoom-amount="1.5" :show-message-touch="false" :tap-to-close="true" regular="/exchanges/explainers/bitget_find_your_subaccount.jpg" zoom="/exchanges/explainers/bitget_find_your_subaccount.jpg" />
                    
                    <p>Go to the next tab <span class="fw-bold">Create API</span> to create your API credentials...</p>
                  </div>
                  <div class="tab-pane fade text-center" id="tab-create-api" role="tabpanel">
                    <p>
                      Now let's create the API Keys.<br>
                      For that click on the <a href="https://www.bitget.com/account/newapi" target="_blank" ><span class="fw-bold">API keys</span></a> menu items on the left hand side. 
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/bitget_apikeys_menu_item.jpg" />

                    <p>Click on <span class="fw-bold">Create API key</span>.</p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/bitget_create_api_button.jpg" />

                    <p>Choose the <span class="fw-bold">API Type</span> as shown.</p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/bitget_select_api_type.jpg" />

                    <p>
                      Choose <span class="fw-bold">settings</span> as shown.<br>
                      Choose a <span class="fw-bold">Passphrase</span> and <span class="fw-bold">remember</span> it. You will need it for the SIGNUM setup.
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/bitget_api_settings_1.jpg" />

                    <p>
                      Choose the <span class="fw-bold">permissions</span> as shown.
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/bitget_api_settings_2.jpg" />

                    <p>
                      Add SIGNUM's IP address, for additional security: <span class="fw-bold">18.199.127.118</span><br>
                      Click <span class="fw-bold">Next</span> and do <span class="fw-bold">2FA</span> as needed.
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/bitget_api_settings_3.jpg" />
                    
                    <p>
                      Now paste your new <span class="fw-bold">API Key, API Secret & Passphrase</span> (the one you defined above) into SIGNUM.<br>
                      Click <span class="fw-bold">Validate</span>.
                    </p>
                    <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/bitget_copy_credentials_to_signum.jpg" />

                    <p>Go to the next tab <span class="fw-bold">Fund Account</span> to fund your Subaccount...</p>
                  </div>
                  <div class="tab-pane fade text-center" id="tab-fund-account" role="tabpanel">
                    <p>
                      To send funds to your Sub-account, go to your <a href="https://www.bitget.com/account/sub-account" target="_blank"><span class="fw-bold">Sub-accounts</span></a>.
                    </p>
                    <p>
                      Click on <span class="fw-bold">Transfer</span> for the Sub-account in question.<br>
                      Select the <span class="fw-bold">From</span> account where your funds are now.<br>
                      Select the <span class="fw-bold">Sub-account</span> and <span class="fw-bold">Spot</span> as your <span class="fw-bold">To</span> destination.<br>
                      <span class="fw-bold">Select a coin</span> and the <span class="fw-bold">Transfer amount</span>.<br>
                      Click <span class="fw-bold">Confirm</span>.
                    </p>
                    <!-- <img class="img-fluid rounded mx-auto d-block mb-3" src="/exchanges/explainers/bitget_transfer_funds.jpg" /> -->
                    <vue-image-zoomer img-class="img-fluid rounded mx-auto d-block mb-3" :click-zoom="true" :zoom-amount="1.5" :show-message-touch="false" :tap-to-close="true" regular="/exchanges/explainers/bitget_transfer_funds.jpg" zoom="/exchanges/explainers/bitget_transfer_funds.jpg" />

                    <p><span class="fw-bold">Done :)</span></p>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        
      </div>
    </div> <!-- row -->

  </form>

</template>

<script>
import TheNavbar from '@/components/TheNavbar'
import BotEditForm from '@/components/BotEditForm'
import Tab from 'bootstrap/js/dist/tab.js'
import { VueImageZoomer } from 'vue-image-zoomer' // https://www.npmjs.com/package/vue-image-zoomer
import 'vue-image-zoomer/dist/style.css';

export default {
  components: {
    TheNavbar,
    BotEditForm,
    VueImageZoomer
  },
  props: {
  },
  data () {
    return {
      step: 1,
      loading: false,
      selectedExchange: null,
      form: {
        title: '',
        enabled: true,
        exchangeId: '',
        exchangeConfig: {
          passphrase: '',
          apiKey: '',
          apiSecret: '',
          isTestNet: false
        },
        baseCoin: '',
        quoteCoin: '',
        ipAllowedTradingView: true,
        ipAllowedMake: false,
        ipAllowedCustomList: ''
      },
      formValidations: {
        apiKey: ''
      }
    }
  },
  computed: {
    userProfile () {
      return this.$store.getters.getUserProfile
    },
    userBots () {
      return this.$store.getters.getUserBots
    },
    maxUserBots () {
      if (this.userProfile.isAutotradingUser) {
        return process.env.VUE_APP_DEFAULT_MAX_BOTS_AT_USERS
      }

      return process.env.VUE_APP_DEFAULT_MAX_BOTS
    },
    platformExchanges () {
      return this.$store.getters.getPlatformExchanges
    },
    apiKeyLabelName () {
      let name = 'API Key'
      if (this.selectedExchange.id == 'coinbase') name = 'API Key Name'
      if (this.selectedExchange.id == 'mexc') name = 'Access Key'
      return name
    },
    apiSecretLabelName () {
      let name = 'API Secret'
      if (this.selectedExchange.id == 'coinbase') name = 'Private Key'
      if (this.selectedExchange.id == 'mexc') name = 'Secret Key'
      return name
    }
  },
  methods: {
    renderExchange (exchange) {
      const userProfile = this.$store.getters.getUserProfile
      if (userProfile?.roles[0] == 'ROLE_ADMIN' && exchange.supported == false) return true
      if (exchange.supported == true) return true
      return false
    },
    selectExchange (exchange) {
      this.selectedExchange = exchange
      this.form.exchangeId = exchange.id

      // Clear all error messages when switching between exchanges
      this.formValidations = {}
    },
    getSupportUrl () {
      return process.env.VUE_APP_SUPPORT_URL
    },
    getSelectedExchangeClasses (exchangeId) {
      let classes = ' '

      if (this.selectedExchange?.id == exchangeId) {
        classes += 'img-thumbnail'
      } else {
        classes += 'opacity-25'
      }

      return classes
    },
    async onSave (event) {
      this.loading = true

      let newBot = event.newBot

      this.form.title = newBot.title
      this.form.exchange = this.form.exchangeId
      this.form.baseCoin = newBot.baseCoin
      this.form.quoteCoin = newBot.quoteCoin

      // Create the POST object
      let postData = this.form
      // Fix the JSON.stringify issue for Coinbase
      postData.exchangeConfig.apiSecret = this.form.exchangeConfig.apiSecret.replace(/\\n/g, "\n")
 
      // console.log("newBot", this.form)
      const newBotFromApi = await this.$store.dispatch('createBot', postData)
      this.$router.push({ name: 'EditBot', params: {id: newBotFromApi.id}, query: {cameFrom: 'createBot'} })

      this.loading = false
    },
    async validateExchange () {
      this.loading = true
      this.formValidations = {}

      if (!this.form.exchangeId) {
        this.formValidations.apiKey = "Please pick an exchange"
        this.loading = false
        return false
      }

      if (!this.form.exchangeConfig.apiKey) {
        this.formValidations.apiKey = "Enter your "+this.apiKeyLabelName
        this.loading = false
        return false
      }

      if (!this.form.exchangeConfig.apiSecret) {
        this.formValidations.apiKey = "Enter your "+this.apiSecretLabelName
        this.loading = false
        return false
      }

      // Validate passphrase for the exchanges who need it
      if (this.form.exchangeId == 'kucoin' || this.form.exchangeId == 'bitget') {
        if (!this.form.exchangeConfig.passphrase) {
          this.formValidations.apiKey = "Enter your API Passphrase"
          this.loading = false
          return false
        }
      }

      if (this.form.exchangeId && this.form.exchangeConfig.apiKey && this.form.exchangeConfig.apiSecret) {

        const userProfile = this.$store.getters.getUserProfile

        // Validate if API Key is already in use and where
        // Fetch userBots if not available
        if (!this.$store.getters.getUserBots) this.$store.dispatch('getUserBots')

        // Find the bot with the API key
        const botByApiKey = this.$store.getters.getUserBots.find(elem => elem.exchangeConfig.apiKey === this.form.exchangeConfig.apiKey)
        if (botByApiKey) {
          this.formValidations.apiKey = `These credentials are already in use by another bot with the title "${ botByApiKey.title }". It's best practice to separte each trading bot into it's own account to separate the budgets and keep them from overlapping. Create a new (sub)-account and use those credentials."`
          this.loading = false
          return false
        }

        // Create the POST object
        let postData = {
          passphrase: this.form.exchangeConfig.passphrase,
          apiKey: this.form.exchangeConfig.apiKey,
          secretKey: this.form.exchangeConfig.apiSecret.replace(/\\n/g, "\n"),
          isTestNet: false
        }

        // Make the api call
        const url = process.env.VUE_APP_API_URL+'/exchange/credentials/'+this.form.exchangeId
        const rawResponse = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          headers: {
            'Authorization': 'Bearer '+userProfile.access_token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify(postData), // body data type must match "Content-Type" header
        })
        
        if (rawResponse.status === 200) {          
          this.step = 2
          this.loading = false
          return true
        } else {
          try {
            const response = await rawResponse.json()
            this.formValidations.apiKey = response.error+': '+response.description
            this.loading = false
            return false
          } catch (e) {
            console.log(e)
          }

          this.formValidations.apiKey = "These credentials are not valid"
        }
      }
      
      this.loading = false
      return false
    },
    cancel () {
      this.$router.back()
    }
  },
  async created () {
    if (!this.$store.getters.getPlatformExchanges) await this.$store.dispatch('getPlatformExchanges')
    if (!this.$store.getters.getUserBots) await this.$store.dispatch('getUserBots')

    // Inform user about max bots reached
    if (this.userBots?.length >= this.maxUserBots) {
      this.step = 3
    }

    // Select the first Exchange so the UI is properly initialized
    // this.selectExchange(this.platformExchanges[0])
    // this.selectedExchange = this.platformExchanges[0]
  },
  async beforeMount () {
    // Redirect to Login when the user is not logged in
    if (!this.$store.getters.isUserLoggedIn) this.$router.push({ name: 'Login' })
  },
  mounted () {
    console.debug(Tab) // Must use Tab but don't actually need to
  },
  unmounted () {
    // TODO: Is this really needed?
    // Reset data to be sure it's clean when editing the next bot
    // this.form = {}
    // this.formValidations = {}
  }
}
</script>

<style scoped>
  img.exchange {
    cursor: pointer;
  }
</style>