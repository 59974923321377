export const strategyAccessMixin = {
  computed: {
    userProfile () {
      return this.$store.getters.getUserProfile
    },
    hasAccessToStrategies() {
      if (this.userProfile.hasStrategyAccess) {
        return true
      }
      return false
    }
  },
  methods: {
  },
  async created () {
    await this.$store.dispatch('getUserProfile') // Needed to have the latest this.userProfile.hasStrategyAccess information in case the cronjob took it from the user
  }
}