<template>
  <form @submit.prevent="" class="needs-validation" novalidate>
    <div class="row mb-3 justify-content-center">
      <div class="col">
        
        <div v-if="isTvUsernameNew">
          <div class="alert alert-warning border-0 d-flex align-items-center" role="alert">
            <div class="bg-warning me-3 icon-item"><span class="fas fa-exclamation-circle text-white fs-6"></span></div>
            <p class="mb-0 flex-1">
              <span class="fw-bold">Only one username can have access to all SIGNUM Strategies at a time.</span>
              <br>
              <br>
              Changing the username will remove access for the existing username <span class="fw-bold">{{ userProfile.tradingViewUserName }}</span> and give access to the new username <span class="fw-bold">{{ tempForm.tvUsername }}</span> for all strategies automatically!
            </p>
          </div>
        </div>
        
        <div class="form-floating">  
          <input v-model="tempForm.tvUsername" class="form-control" type="text">
          <label for="floatingInput">Your TradingView Username</label>
          <div v-if="formValidations.tvUsername" class="invalid-feedback d-block fs-10">{{ formValidations.tvUsername }}</div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-4 d-flex justify-content-start mb-2">
        <button @click="back" type="submit" class="btn btn-secondary">Back</button>&nbsp;
      </div>
      <div class="col-8 d-flex justify-content-end text-end mb-2">
        <button v-if="!loading" @click="submitAccessForm()" type="submit" class="btn btn-primary">
          <span v-if="isTvUsernameNew">Give & Take Access Now</span>
          <span v-else>Give Access Now</span>
        </button>

        <div v-if="loading" class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>

      </div>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    // loading: {
    //   required: true,
    //   type: Boolean
    // },
    strategyId: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      loading: false,
      tempForm: {
        tvUsername: ''
      },
      formValidations: {
      }
    }
  },
  computed: {
    userProfile () {
      return this.$store.getters.getUserProfile
    },
    isTvUsernameNew () {
      // Remove spaces before comparing
      const existingUsername = this.userProfile.tradingViewUserName?.replace(/\s/g, '')
      const newUsername = this.tempForm.tvUsername?.replace(/\s/g, '')

      if (existingUsername != "" && existingUsername != newUsername){
        return true
      }
      return false
    }
  },
  methods: {
    validateAccessForm() {
      this.formValidations = {}

      // Remove spaces
      this.tempForm.tvUsername = this.tempForm.tvUsername.replace(/\s/g, '')

      if (!this.tempForm.tvUsername || this.tempForm.tvUsername == '') {
        this.formValidations.tvUsername = "Enter your TradingView username."
        return false
      }

      return true
    },
    async submitAccessForm () {
      this.loading = true      

      if (this.validateAccessForm()) {
        // Update profile
        await this.$store.dispatch('updateUserProfile', {
          tradingViewUserName: this.tempForm.tvUsername
        })

        // Give user access
        const addResult = await this.$store.dispatch('addUserToStrategy', [this.strategyId])
        if (addResult) {
          // Play confetti to make user happy
          this.$confetti.start()
            setTimeout(() => {
              this.$confetti.stop()
            }, "2000");
        }
      }

      this.loading = false
    },
    back () {
      this.$router.push({ name: 'Strategies' })
    }
  },
  async created () {
    if (this.userProfile.tradingViewUserName) this.tempForm.tvUsername = this.userProfile.tradingViewUserName
  }
}
</script>